<template>
  <div>
    <div class="user-info">
      <div class="systemTypeBtns" v-if="isShowsystemTypeBtns">
        <a-select
          v-model="value"
          placeholder="请选择搜索条件"
          class="search-default-value"
          @change="changeSystem"
        >
          <a-select-option
            v-for="opt in SystemList"
            :key="opt.value"
            :value="opt.value"
            >{{ opt.label }}</a-select-option
          >
        </a-select>
      </div>
      <a-dropdown>
        <a @click="(e) => e.preventDefault()">
          <!-- <img src="../assets/images/user_img.png" class="head-img" /> -->
          <img :src="avatarImg" class="head-img" />
          <span class="user-name">{{ this.$store.state.user.name }}</span>
        </a>
        <a-menu slot="overlay">
          <a-menu-item>
            <a @click="changePwd()">修改密码</a>
          </a-menu-item>
          <a-menu-item>
            <a @click="doLogOut()">退出登录</a>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
    <div>
      <user-edit-pass
        :u_id="u_id"
        v-if="changePwdShow"
        @onClose="onClose"
      ></user-edit-pass>
    </div>
  </div>
</template>
<script>
import UserEditPass from "./UserEditPass";
import store from "../store";
import router from "../router";
// import { resetRouter } from "../router";

import { DoLogout } from "@/api/login";
export default {
  name: "UserInfo",
  components: { UserEditPass },
  data() {
    return {
      name: this.$store.state.user.name,
      u_id: this.$store.state.user.id,
      changePwdShow: false,
      value: "xc",
      isShowsystemTypeBtns: false,
      avatarImg: this.$store.state.user.avatar,
      SystemList: [
        // { value: "xc", label: "现场管理系统" },
        // { value: "jf", label: "交付系统" },
        // { value: "yx", label: "营销系统" },
        // { value: "cw", label: "财务系统" },
      ],
    };
  },
  created() {
    setTimeout(() => {
      this.u_id = this.$store.state.user.id;
      // console.log("userinfo", this.$store.state.user.menus);
      // 现场管理菜单
      let roles = this.$store.state.user.menus.osmcore
        ? this.$store.state.user.menus.osmcore
        : [];
      // 交付系统菜单
      let rolesJF = this.$store.state.user.menus.dsmcore
        ? this.$store.state.user.menus.dsmcore
        : [];
      // 营销系统菜单
      let rolesYX = this.$store.state.user.menus.mkscore
        ? this.$store.state.user.menus.mkscore
        : [];
      if (roles && roles.length > 0) {
        this.SystemList.push({
          value: "xc",
          label: "现场管理系统",
        });
      }
      if (rolesJF && rolesJF.length > 0) {
        this.SystemList.push({
          value: "jf",
          label: "交付系统",
        });
      }
      if (rolesYX && rolesYX.length > 0) {
        this.SystemList.push({
          value: "yx",
          label: "营销系统",
        });
      }
      // 是否展示选择系统的选择框
      if (this.SystemList.length > 1) {
        this.isShowsystemTypeBtns = true;
      } else {
        this.isShowsystemTypeBtns = false;
      }

      let SET_SYSTEMValue = localStorage.getItem("SET_SYSTEM");
      // 如果本地存储的有选择哪个系统
      if (SET_SYSTEMValue) {
        // console.log("SET_SYSTEMValue", SET_SYSTEMValue);
        this.$store.commit("SET_SYSTEM", SET_SYSTEMValue);
        this.value = SET_SYSTEMValue;
        // if (SET_SYSTEMValue == "xc") {
        //   this.isShowsystemTypeBtns = true;
        // } else {
        //   this.isShowsystemTypeBtns = true;
        // }
      } else {
        // if (rolesJF == "") {
        //   // this.isShowsystemTypeBtns = false;
        //   this.value = "xc";
        //   this.$store.commit("SET_SYSTEM", "xc");
        // } else {
        //   // this.isShowsystemTypeBtns = true;
        //   this.$store.commit("SET_SYSTEM", "jf");
        // }
        // 如果没有指定那个系统，则展示第一个系统
        this.value = this.SystemList[0].value;
        this.$store.commit("SET_SYSTEM", this.SystemList[0].value);
      }
    }, 100);
  },
  methods: {
    doLogOut() {
      DoLogout()
        .then((response) => {
          if (response.code == "200") {
            localStorage.removeItem("userMsg");
            this.$store.commit("SET_ROLES", "");
            this.$store.commit("SET_ROLESJF", "");
            sessionStorage.removeItem("token");
            this.$router.push({ path: "/login" });
            sessionStorage.removeItem("roles");

            localStorage.removeItem("path", "");
            localStorage.removeItem("SET_SYSTEM");
            this.$store.commit("SET_CURRENT", "");
            this.$store.commit("SET_SYSTEM", "");
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
      // localStorage.removeItem("userMsg");
      // this.$store.commit("SET_CURRENT", "");
    },
    onClose() {
      this.changePwdShow = false;
    },
    changePwd() {
      this.changePwdShow = true;
    },
    changeSystem(e) {
      let value = e;
      // console.log("选择现场管理系统或交付系统", value);
      this.$store.commit("SET_SYSTEM", value);
      localStorage.setItem("SET_SYSTEM", value);
      let menus = [];
      let that = this;
      if (value == "xc") {
        menus = this.$store.state.user.menus.osmcore;
      } else if (value == "jf") {
        menus = this.$store.state.user.menus.dsmcore;
      } else if (value == "yx") {
        menus = this.$store.state.user.menus.mkscore;
      }
      store.dispatch("GenerateRoutes", menus).then((accessRoutes) => {
        // resetRouter();
        // 根据 roles 权限生成可访问的路由表
        // console.log("accessRoutes", accessRoutes);
        router.addRoutes(accessRoutes); // 动态添加可访问路由表
        let lastRou = [{ path: "*", redirect: "/404", hidden: true }];
        router.addRoutes(lastRou);

        if (value == "jf") {
          let GoToPath = menus[0].path + "/" + menus[0].children[0].path;
          that.$router.push({ path: GoToPath });
        } else {
          that.$router.push({ path: "/" });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.user-info {
  position: fixed;
  top: 0;
  right: 40px;
  display: flex;
}
.head-img {
  padding-right: 6px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.user-name {
  color: #595959;
  font-size: 14px;
  vertical-align: middle;
}
.systemTypeBtns {
  margin-right: 30px;
}
</style>
