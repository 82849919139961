import router from "./router";
// import { resetRouter } from "./router";
import store from "./store";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
// import { isRelogin } from "@/utils/request";

NProgress.configure({ showSpinner: false });
// 增加三方登陆 update by 芋艿
const whiteList = [
  "/login",
  // "/index",
  // "/HomeMaster",
  // "/404",
  // "/system/user",
];
// console.log("router", router);

router.beforeEach((to, from, next) => {
  NProgress.start();
  // console.log("to", to);

  if (sessionStorage.getItem("token")) {
    to.meta.title && store.dispatch("settings/setTitle", to.meta.title);
    /* has token*/
    if (to.path === "/login") {
      next({ path: "/" });
      // next({ path: "/login" });

      NProgress.done();
    } else {
      // console.log("store.getters.roles.length", store.getters.roles.length);

      if (store.getters.roles.length === 0) {
        // isRelogin.show = true;
        // 获取字典数据 add by 芋艿
        // store.dispatch("dict/loadDictDatas");
        // 判断当前用户是否已拉取完 user_info 信息
        store
          .dispatch("GetInfo")
          .then((userInfo) => {
            // console.log("userInfo", userInfo);
            // // 传递的菜单--现场管理系统 or 交付系统
            let systemMenu = store.state.user.system;

            let menus = [];
            if (systemMenu == "jf") {
              menus = userInfo.menus.dsmcore;
            } else if (systemMenu == "xc") {
              menus = userInfo.menus.osmcore;
            } else if (systemMenu == "yx") {
              menus = userInfo.menus.mkscore;
            }

            // isRelogin.show = false;
            // 触发 GenerateRoutes 事件时，将 menus 菜单树传递进去
            store.dispatch("GenerateRoutes", menus).then((accessRoutes) => {
              // resetRouter();
              // 根据 roles 权限生成可访问的路由表

              router.addRoutes(accessRoutes); // 动态添加可访问路由表
              let lastRou = [{ path: "*", redirect: "/404", hidden: true }];
              router.addRoutes(lastRou);
              next({ ...to, replace: true }); // hack方法 确保addRoutes已完成
              // console.log("系统", systemMenu);

              // if (systemMenu == "jf") {
              //   let GoToPath = menus[0].path + "/" + menus[0].children[0].path;
              //   next(GoToPath);
              // } else {
              //   next({ path: "/" });
              // }

              // console.log("router", router);
            });
          })
          .catch((err) => {
            store.dispatch("LogOut").then(() => {
              Message.error(err);
              console.log("err", err);
              store.commit("SET_ROLES", "");
              store.commit("SET_ROLESJF", "");
              store.commit("SET_CURRENT", "");
              store.commit("SET_SYSTEM", "");
              next({ path: "/login" });
            });
          });
      } else {
        next();
      }
    }
  } else {
    // // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      const redirect = encodeURIComponent(to.fullPath); // 编码 URI，保证参数跳转回去后，可以继续带上
      // next(`/login?redirect=${redirect}`); // 否则全部重定向到登录页
      // next(`/login`); // 否则全部重定向到登录页
      next("/login");

      NProgress.done();
    }
    // next({ path: "/login" });
    // this.$route.push({ path: "/login" });
  }
});

router.afterEach(() => {
  NProgress.done();
});
