import request from "@/utils/request";

const api = {
  getRoles: "/dsmcore/api/role/rolelist", // 查询角色信息列表
  getRoleDetail: "/dsmcore/api/role/details", // 查询角色详情
  addRole: "/dsmcore/api/role/addrole", // 添加角色
  editRole: "/dsmcore/api/role/editrole", // 修改角色
  deleteRole: "/dsmcore/api/role/delrole", // 删除角色

  getUsers: "/dsmcore/api/user/userlist", // 查询用户列表
  getUserDetail: "/dsmcore/api/user/userdetails", // 查询用户详情
  addUser: "/dsmcore/api/user/adduser", // 添加用户
  editUser: "/dsmcore/api/user/edituser", // 修改用户
  deleteUser: "/dsmcore/api/user/deluser", // 删除用户

  // getCompany: "/dsmcore/api/company/companylist", //获取组织数据
  getCompany: "/user/system/company/companylist", //获取组织数据

  getUser: "/user/api/user/userdata", //获取用户信息
  getpermissioninfo: "/user/system/auth/get-permission-info", //新版获取用户信息
  // updatePass: "/user/api/user/uppass", // 修改密码
  updatePassword: "/user/system/user/profile/update-password",

  // 组织管理
  getOrderList: "/dsmcore/api/company/orderlist", //组织管理列表
  updateCompanystore: "/dsmcore/api/companystore/updatecompanystore", // 修改门店信息
  addcompanystore: "/dsmcore/api/companystore/addcompanystore", //新增门店信息
  deletecompanystore: "/dsmcore/api/companystore/deletecompanystore", //删除门店信息
  setSelfupdate: "/dsmcore/api/setSelf/update", //更新个性化设置信息
  selectByBcId: "/dsmcore/api/setSelf/selectByBcId", //查询个性化设置信息
  userLog: "/dsmcore/api/userLog/selectByUlBase", //查询操作日志信息
  // 页面管理
  pagelist: "/dsmcore/api/page/pagelist", //查询页面列表
  addpage: "/dsmcore/api/page/addpage", //添加页面
  editpage: "/dsmcore/api/page/editpage", //修改页面
  delpage: "/dsmcore/api/page/delpage", //删除页面
  addpagepower: "/dsmcore/api/pagepower/addpagepower", //添加页面接口
  editpagepower: "/dsmcore/api/pagepower/editpagepower", //修改页面接口
  delpagepower: "/dsmcore/api/pagepower/delpagepower", //删除页面接口
  addcompany: "/dsmcore/api/company/addcompany", //添加组织信息
  updatecompany: "/dsmcore/api/company/updatecompany", //修改组织信息
  delcompany: "/dsmcore/api/company/delcompany", //删除组织信息
};

export function GetUser() {
  return request({
    url: api.getUser,
    method: "post",
  });
}
// getpermissioninfo
export function GetPermissionInfo() {
  return request({
    url: api.getpermissioninfo,
    method: "get",
  });
}
export function GetCompany() {
  return request({
    url: api.getCompany,
    method: "get",
  });
}

export function GetRoles(parameter) {
  return request({
    url: api.getRoles,
    method: "post",
    params: parameter,
  });
}

export function GetRoleDetail(parameter) {
  return request({
    url: api.getRoleDetail,
    method: "post",
    params: parameter,
  });
}

export function AddRole(parameter) {
  return request({
    url: api.addRole,
    method: "post",
    params: parameter,
  });
}

export function EditRole(parameter, data) {
  return request({
    url: api.editRole,
    method: "post",
    params: parameter,
    data: data,
    // contentType: "text/html;charset=UTF-8",
  });
}

export function DeleteRole(parameter) {
  return request({
    url: api.deleteRole,
    method: "post",
    params: parameter,
  });
}

export function GetUsers(parameter) {
  return request({
    url: api.getUsers,
    method: "post",
    params: parameter,
  });
}

export function GetUserDetail(parameter) {
  return request({
    url: api.getUserDetail,
    method: "post",
    params: parameter,
  });
}

export function AddUser(parameter) {
  return request({
    url: api.addUser,
    method: "post",
    params: parameter,
  });
}

export function EditUser(parameter) {
  return request({
    url: api.editUser,
    method: "post",
    params: parameter,
  });
}

export function DeleteUser(parameter) {
  return request({
    url: api.deleteUser,
    method: "post",
    params: parameter,
  });
}

export function UpdatePass(parameter) {
  return request({
    url: api.updatePass,
    method: "post",
    params: parameter,
  });
}

export function UpdatePassWord(parameter) {
  return request({
    url: api.updatePassword,
    method: "put",
    data: parameter,
  });
}
export function GetOrderList(parameter) {
  return request({
    url: api.getOrderList,
    method: "post",
    params: parameter,
  });
}

export function UpdateCompanystore(parameter) {
  return request({
    url: api.updateCompanystore,
    method: "post",
    params: parameter,
  });
}
export function Addcompanystore(parameter) {
  return request({
    url: api.addcompanystore,
    method: "post",
    params: parameter,
  });
}

export function DeleteCompanystore(parameter) {
  return request({
    url: api.deletecompanystore,
    method: "post",
    params: parameter,
  });
}
export function setSelfupdate(parameter, data) {
  return request({
    url: api.setSelfupdate,
    method: "post",
    params: parameter,
    data: data,
  });
}
export function GetselectByBcId(parameter, data) {
  return request({
    url: api.selectByBcId,
    method: "post",
    params: parameter,
    data: data,
  });
}
export function GetuserLog(parameter) {
  return request({
    url: api.userLog,
    method: "post",
    params: parameter,
  });
}

export function GetPagelist(parameter) {
  return request({
    url: api.pagelist,
    method: "post",
    params: parameter,
  });
}
export function AddPage(parameter) {
  return request({
    url: api.addpage,
    method: "post",
    params: parameter,
  });
}
export function EditPage(parameter) {
  return request({
    url: api.editpage,
    method: "post",
    params: parameter,
  });
}
export function DelPage(parameter) {
  return request({
    url: api.delpage,
    method: "post",
    params: parameter,
  });
}
export function AddPagepower(parameter) {
  return request({
    url: api.addpagepower,
    method: "post",
    params: parameter,
  });
}
export function EditPagepower(parameter) {
  return request({
    url: api.editpagepower,
    method: "post",
    params: parameter,
  });
}
export function DelPagepower(parameter) {
  return request({
    url: api.delpagepower,
    method: "post",
    params: parameter,
  });
}

export function Addcompany(parameter) {
  return request({
    url: api.addcompany,
    method: "post",
    params: parameter,
  });
}
export function Updatecompany(parameter) {
  return request({
    url: api.updatecompany,
    method: "post",
    params: parameter,
  });
}
export function Delcompany(parameter) {
  return request({
    url: api.delcompany,
    method: "post",
    params: parameter,
  });
}
